<script lang="ts">
    import cn from '$src/utils/cn';
    import ChevronLeftIcon from '$src/icons/ChevronLeftIcon.svelte';
    import ChevronRightIcon from '$src/icons/ChevronRightIcon.svelte';

    export let calendar,
        prevButton,
        heading,
        headingValue,
        nextButton,
        months,
        grid,
        weekdays,
        isDateDisabled,
        isDateUnavailable,
        cell;
    export let isMultipleMonths = false;
    export let monthsOrientation: 'vertical' | 'horizontal' = 'horizontal';
</script>

<div {...$calendar} use:calendar>
    <div class="flex items-center justify-between pb-2">
        <button type="button" {...$prevButton} use:prevButton>
            <ChevronLeftIcon class="size-xl" />
        </button>
        <div
            {...$heading}
            use:heading
            class="flex w-full justify-between gap-x-8 text-m capitalize text-content-neutral"
        >
            {#if isMultipleMonths}
                <div class="flex-1 text-center">
                    {$headingValue.split('-')[0]}
                </div>
                {#if monthsOrientation === 'horizontal'}
                    <div class="flex-1 text-center">
                        {$headingValue.split('-')[1]}
                    </div>
                {/if}
            {:else}
                <div class="flex-1 text-center">
                    {$headingValue}
                </div>
            {/if}
        </div>
        <button type="button" {...$nextButton} use:nextButton>
            <ChevronRightIcon class="size-xl" />
        </button>
    </div>
    <div
        class={cn(
            isMultipleMonths && 'flex gap-x-8',
            monthsOrientation === 'vertical' && 'flex-col',
        )}
    >
        {#each $months as month, index}
            {#if monthsOrientation === 'vertical' && index >= 1}
                <div
                    {...$heading}
                    use:heading
                    class="mt-m w-full pb-xs text-center text-sm capitalize text-content-neutral"
                >
                    {$headingValue.split('-')[1]}
                </div>
            {/if}
            <table {...$grid} use:grid>
                <thead aria-hidden="true">
                    <tr class="border-8 border-solid border-white">
                        {#each $weekdays as day}
                            <th
                                class="text-center text-xs font-semibold leading-4 text-content-quiet"
                            >
                                {day}
                            </th>
                        {/each}
                    </tr>
                </thead>
                <tbody>
                    {#each month.weeks as days}
                        <tr>
                            {#each days as date}
                                <td
                                    class={cn('text-center text-xs leading-4')}
                                    role="gridcell"
                                    aria-disabled={$isDateDisabled(date) ||
                                        $isDateUnavailable(date)}
                                >
                                    <div
                                        {...$cell(date, month.value)}
                                        use:cell
                                        class="flex items-center justify-center"
                                    >
                                        <div>
                                            {date.day}
                                        </div>
                                    </div>
                                </td>
                            {/each}
                        </tr>
                    {/each}
                </tbody>
            </table>
        {/each}
    </div>
</div>

<style lang="postcss">
    [data-melt-popover-content] {
        @apply z-50 border border-solid border-bord-quiet bg-white;
    }
    [data-melt-calendar] {
        @apply h-full w-full px-2xl pt-m;
    }

    table {
        @apply border-spacing-xs;
        border-collapse: separate;
    }

    [data-melt-calendar-cell] {
        @apply size-3xl bg-container-quiet desktop:size-[36px];

        &:hover {
            @apply bg-container-commercial-neutral;
        }
    }

    [data-melt-calendar-cell][data-disabled] {
        @apply pointer-events-none cursor-default border-white bg-white text-content-quiet;
    }

    [data-melt-calendar-cell][data-unavailable] {
        @apply pointer-events-none cursor-default text-content-quiet;
    }

    [data-melt-calendar-cell][data-selected] {
        @apply border-border-brand bg-blue-brand text-white;
    }

    [data-melt-calendar-cell][data-selected][data-disabled] {
        @apply pointer-events-none cursor-default border-white bg-white text-content-quiet;
    }

    [data-melt-calendar-cell][data-outside-visible-months] {
        @apply pointer-events-none cursor-default text-content-quiet;
    }
</style>
